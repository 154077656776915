<template>
  <div class="main">
    <nyheads />
    <div class="ny-nav">
      <router-link to="/">论文助手</router-link
      >&nbsp;&nbsp;>&nbsp;&nbsp;个人中心
    </div>
    <div class="ny-con">
      <grzxnav/>
      <div class="grzx-con">
        <el-divider content-position="left">写作记录</el-divider>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="title" label="论文标题" />
          <el-table-column label="当前状态" width="150">
            <template #default="scope">
              <span v-if="scope.row.rstatus == 1" style="color: #888"
                >待生成</span
              >
              <span v-if="scope.row.rstatus == 2" style="color: #e6a23c"
                >生成中</span
              >
              <span v-if="scope.row.rstatus == 3" style="color: #409eff"
                >已完成</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="ctime" label="提交时间" width="200" />
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope">
              <el-button
                type="warning"
                v-if="scope.row.rstatus == 1 || scope.row.rstatus == 2"
                size="small"
                @click="shuaxinzhuangtai"
              >
                刷新
              </el-button>
              <el-button
                v-if="scope.row.rstatus == 3 && scope.row.downds==1"
                type="primary"
                size="small"
                @click="chucidownloadword(scope.row.Id,scope.row.wordurl)"
              >
                下载
              </el-button>
              <el-button
                v-if="scope.row.rstatus == 3 && scope.row.downds==2"
                type="danger"
                size="small"
                @click="downloadword(scope.row.wordurl)"
              >
                重下
              </el-button>
              
            </template>
          </el-table-column>
        </el-table>
        <div class="tablesfy-fy">
          <el-pagination
            background
            :current-page="currentPage"
            :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totals"
            @size-change="handleSizeChange"
            @current-change="fanye"
          />
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>

<script setup>
import { post, get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, ref,onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import {
  Document,
  Menu as IconMenu,
  Location,
  Setting,
} from "@element-plus/icons-vue";

const router = useRouter();

// 表格数据
const tableData = reactive([]);
// 分页先关
let totals = ref(0);
let page = ref(1);
let currentPage = ref(1);
let pageSize = ref(10);

// 获取列表
const getlist = (pg) => {
  tableData.length = 0;
  post("/api/Paper/GetUserPaperList", {
    lx:5,
    page: pg,
    psize: pageSize.value,
  })
    .then((res) => {
      totals.value = res.total;
      tableData.push(...res.data);
      currentPage.value = pg;
    })
    .catch((err) => {
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};
getlist(1);
// 分页
const fanye = (val) => {
  page.value = val;
  getlist(val);
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getlist(1);
};

// 刷新
const shuaxinzhuangtai = () => {
  getlist(1);
  ElMessage({
    message: "状态已更新！",
    duration: 1000,
  });
};
// 初次下载更改状态
const chucidownloadword=(id,urls)=>{
  get("/api/Paper/UserDownPaper",{
    id:id,
  }).then((res) => {
    downloadword(urls)
  });
}

// 下载文档
const downloadword = (urls) => {
  let downloadurl = urls;
  const link = document.createElement("a");
  link.setAttribute("href", downloadurl); //设置下载文件的url地址
  //link.setAttribute('download', 'download'); //用于设置下载文件的文件名
  link.click();
};




</script>

<style scoped>
:deep(.el-menu) {
  border-right: 0;
}
:deep(.el-divider__text) {
  font-weight: bold;
  font-size: 18px;
}
.main {
  background: #ebedf3;
}
.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}
.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1200px;
  height: auto;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  border: 1px solid #dddfe5;
  border-radius: 2px;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.ny-con:after {
  content: "";
  display: table;
  clear: both;
}
.grzx-nav {
  float: left;
  width: 240px;
  min-height: 600px;
  padding-right: 30px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
}
.grzx-con {
  float: right;
  width: calc(100% - 240px);
  height: auto;
  box-sizing: border-box;
  padding-left: 30px;
}
.grzx-con span {
  font-weight: bold;
}

.grzx-nav-tx {
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.grzx-nav-yhm {
  width: calc(100% - 20px);
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.grzx-nav-st {
  padding: 20px 0;
}
.grzx-nav-yhm-lever {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 5px 0;
  background: url("/img/gerenzhongxin/VIP.png");
}
.grzx-nav-yhm-lever-num {
  position: absolute;
  left: 15px;
  top: 15px;
  color: #fff;
  font-weight: bold;
}
.grzx-con-st {
  padding: 10px 0;
}
.grzx-con-st-list {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}
.grzx-con-st-list-title {
  width: 100px;
}
.grzx-con-st-list-txt {
  width: calc(100% - 100px);
}
.gezx-nr-cz {
  margin-top: 20px;
}
.tablesfy-fy{
  margin-top: 10px;
}
</style>
